import * as Mutation from './mutation-types';

export default {
	[Mutation.SET_EVENTS_IS_FETCHING](state) {
		state.eventsIsFetched = false;
	},
	[Mutation.SET_EVENTS_IS_FETCHED](state) {
		state.eventsIsFetched = true;
	},
	[Mutation.SET_EVENTS](state, events) {
		state.events = events.data;
	},
	[Mutation.CLEAR_EVENTS](state) {
		state.events = [];
	},
	//сохраняем активный ивент (по которому кликнули)
	[Mutation.SET_ACTIVE_EVENT](state, activeEvent) {
		const activeEventData = activeEvent.data;
		const count = activeEventData.max_register - activeEventData.count_register;
		state.activeEvent = activeEvent;
		state.activeEventFreeSlots = count;
	},
	[Mutation.CLEAR_ACTIVE_EVENT](state) {
		state.activeEvent = {};
		state.activeEventFreeSlots = null;
		state.isBooked = false;
		state.isRefused = false;
		state.isWaiting = false;
		state.bookingError = '';
	},
	[Mutation.SET_ACTIVE_EVENT_FREE_SLOTS](state, count) {
		state.activeEventFreeSlots = count;
	},
	[Mutation.SET_IS_BOOKED](state, status) {
		state.isBooked = status;
	},
	[Mutation.SET_IS_REFUSED](state, status) {
		state.isRefused = status;
	},
	[Mutation.SET_BOOKING_ERROR](state, message) {
		state.bookingError = message;
	},
	// [Mutation.SET_PHONE_MASK](state, phoneMask) {
	// 	state.phoneMask = phoneMask;
	// },
	// [Mutation.SET_PHONE_MASK_PLACEHOLDER](state, phoneMaskPlaceholder) {
	// 	state.phoneMaskPlaceholder = phoneMaskPlaceholder;
	// },
	[Mutation.INCREASE_EVENTS_COUNTER](state) {
		state.fetchEventsCounter = state.fetchEventsCounter + 1;
	},
	[Mutation.SET_DEFAULT_COUNTRY](state, country) {
		state.defaultCountry = country;
	},
	[Mutation.SET_PREFERRED_COUNTRIES](state, countries) {
		state.preferredCountries = countries;
	},
	[Mutation.SET_PRIMARY_COLOR](state, payload) {
		state.primaryWidgetColor = payload;
	},
	[Mutation.SET_SECONDARY_COLOR](state, payload) {
		state.secondaryWidgetColor = payload;
	},
	[Mutation.SET_IS_WAITING](state, status) {
		state.isWaiting = status;
	},
	[Mutation.SET_OFFER](state, payload) {
		state.offer = payload;
	},
	[Mutation.SET_CLUB_RULES](state, payload) {
		state.clubRules = payload;
	},
	[Mutation.SET_PERSONAL_DATA](state, payload) {
		state.personalData = payload;
	},
	[Mutation.SET_IS_NEED_REGISTER_BY_SCHEDULE](state, payload) {
		state.isNeedRegisterBySchedule = payload;
	},
}
