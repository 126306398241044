<template>
	<button :class="getButtonClassList()" v-bind:style="{background: bg_color}" :type="type">
		<slot></slot>
	</button>
</template>
<script>
export default {
	name: "Button",
	props: {
		color: {
			type: String,
			default: 'blue'
		},
		bg_color: {
			type: String,
			default: ''
		},
		size: {
			type: String,
			default: 'm'
		},
		type: {
			type: String,
			default: 'button'
		},
		nowrap: {
			type: Boolean,
			default: false
		},
	},
	methods: {
		getButtonClassList: function () {
			let classList = `btn btn-${this.color} btn-${this.size} btn-custom-primary`;
			if (this.nowrap) {
				classList += ` btn-nowrap`
			}

			return classList;
		}
	// },
	// computed: {
	// 	styleObject() {
	// 		return { background: this.background }
	// 		//fontSize: '13px'
	// 	}
	}
}
</script>
