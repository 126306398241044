<template>
	<div class="enter_btn">
		<a :href="`${this.domain}/lk${additionalParams}`" target="_blank">
			<i>
				<svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M5.90966 6.92844C6.8615 6.92844 7.68572 6.58706 8.35917 5.91351C9.03262 5.24006 9.374 4.41606 9.374 3.46411C9.374 2.51249 9.03262 1.68838 8.35906 1.01472C7.6855 0.341385 6.86139 0 5.90966 0C4.9577 0 4.1337 0.341385 3.46025 1.01483C2.78681 1.68827 2.44531 2.51238 2.44531 3.46411C2.44531 4.41606 2.78681 5.24017 3.46036 5.91362C4.13392 6.58695 4.95803 6.92844 5.90966 6.92844Z" fill="#3C67D6"/>
					<path d="M11.9709 11.0601C11.9515 10.7798 11.9122 10.4741 11.8544 10.1512C11.796 9.82599 11.7208 9.51851 11.6308 9.23748C11.5379 8.94701 11.4115 8.66017 11.2552 8.38528C11.093 8.09997 10.9025 7.85153 10.6888 7.64709C10.4652 7.43322 10.1916 7.26127 9.87508 7.13584C9.5597 7.01107 9.2102 6.94786 8.83633 6.94786C8.6895 6.94786 8.54751 7.00811 8.27328 7.18665C8.1045 7.29671 7.90709 7.424 7.68674 7.56479C7.49833 7.68484 7.24308 7.79732 6.92781 7.89916C6.62022 7.99869 6.30792 8.04916 5.99967 8.04916C5.69142 8.04916 5.37923 7.99869 5.07131 7.89916C4.75637 7.79743 4.50112 7.68495 4.31293 7.5649C4.09467 7.42543 3.89714 7.29814 3.72584 7.18654C3.45195 7.008 3.30984 6.94775 3.16301 6.94775C2.78903 6.94775 2.43964 7.01107 2.12437 7.13595C1.80811 7.26116 1.53432 7.43311 1.31057 7.6472C1.09691 7.85175 0.906304 8.10008 0.744335 8.38528C0.588181 8.66017 0.461766 8.9469 0.368711 9.23759C0.278838 9.51862 0.203669 9.82599 0.14529 10.1512C0.0874591 10.4736 0.0481738 10.7795 0.0287507 11.0604C0.00965671 11.3356 0 11.6213 0 11.9097C0 12.6606 0.238674 13.2684 0.709329 13.7166C1.17417 14.159 1.78923 14.3834 2.53719 14.3834H9.46281C10.2108 14.3834 10.8256 14.1591 11.2906 13.7166C11.7613 13.2687 12 12.6608 12 11.9096C11.9999 11.6198 11.9901 11.334 11.9709 11.0601Z" fill="#3C67D6"/>
				</svg>
			</i>
			Войти
		</a>
	</div>
</template>

<script>
import {storeToComputed} from "../mixins/storeToComputed";

export default {
	name: "EnterButton",
	mixins: [storeToComputed],
  props: {
    additionalParams: {
      type: String,
      default: function () {
        return '';
      }
    }
  },
}
</script>
