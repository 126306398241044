<template>
	<form>
		<div class="input_wrapper">
			<vue-tel-input
					v-model="phoneNumber"
					v-bind:defaultCountry = "defaultCountry"
					v-bind:inputOptions = "inputOptions"
					v-bind:preferredCountries = "preferredCountries"
					@validate="phoneValidation"
					@input="phoneStringsFormat"
			></vue-tel-input>
			<div class="error_msg" v-if="bookingError">
				{{bookingError}}
			</div>
		</div>
		<Button color="blue" size="m" :bg_color="this.bg" @click.native="sendPhone" :disabled="!isValidated">
			Записаться
		</Button>
	</form>
</template>

<script>
//import MaskedInput from 'vue-masked-input'
//import VueTelInput from 'vue-tel-input'
import Button from "../Button";
import {storeToComputed} from "../mixins/storeToComputed";
// import {mapGetters} from "vuex"
//import {OPEN_BRACKET_CHAR} from "../../utils/forms";

export default {
	name: "EventModalPhoneForm",
	mixins: [storeToComputed],
	components: {
		//MaskedInput,
		//VueTelInput,
		Button
	},
	//  computed: {
	// // 	isValidated() {
	// // 		// let phoneNumber = this.phoneStringsFormat(this.phoneNumber);
	// // 		// let phoneMask = this.phoneStringsFormat(this.phoneMask);
	// // 		//
	// // 		// return phoneNumber.length === phoneMask.length;
	// // 		return true;
	// // 	},
	// 	 ...mapGetters('events',['defaultCountry', 'preferredCountries']),
	//  },
	data() {
		return {
			phoneNumber: '',
			phoneNumberI: '',
			inputOptions: {
				placeholder: 'Ваш телефон'
			},
			isValidated: false,
		}
	},
	methods: {
		phoneStringsFormat: function () {
			// let newStr = str.replace(/\s+/g, '');
			// let openBracketPos = newStr.indexOf(OPEN_BRACKET_CHAR)
			// return newStr.slice(openBracketPos);
			this.phoneNumber = this.phoneNumber.replace(/^8/, '+7');
		},
		sendPhone: function () {
			//this.$emit('sendRegisrationRequest', {phoneNumber: this.phoneStringsFormat(this.phoneNumber)});
			this.$emit('sendRegisrationRequest', {phoneNumber: this.phoneNumberI});
			this.phoneNumber = '';
			this.phoneNumberI = '';
		},
		phoneValidation: function (phone) {
			this.isValidated = phone.valid;
			if (this.isValidated) {
				this.phoneNumberI = phone.countryCallingCode + phone.nationalNumber;
			} else {
				this.phoneNumberI = '';
			}
		},
	},
	computed: {
		bg() {
			return this.isValidated ? this.$store.getters['events/primaryWidgetColor'] : this.$store.getters['events/secondaryWidgetColor'];
		}
	}
}
</script>

<style lang="css">
	@import "~vue-tel-input/dist/vue-tel-input.css";
</style>
