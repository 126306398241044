
<template>
	<div
		id="fitbase-widget"
		ref="fitbaseWidget"
		:class="{
			touch : isTouchDevice,
			mob: isMobilePhone,
			mob_m: isMediumMobilePhone,
			desktop: !isTouchDevice,
			desktop_m: !isTouchDevice && !isSmallDesktop,
			desktop_sm: isSmallDesktop,
			dark: theme === this.DARK_THEME,
			embed_lk: getEmbeddedType === 'lk'
		}"
		v-if="firstScreenIsDispatched"
	>
		<v-style v-if="getEmbeddedType !== 'lk'">
			#fitbase-widget.desktop.dark .wrapper .calendar .fc-day-today .fc-col-header_day_title {
			background: {{getPrimaryColor}}1f !important;
			}
			#fitbase-widget.desktop .wrapper .calendar .fc-day-today .fc-col-header_day_title .day{
				color: {{getPrimaryColor}} !important;
			}
			#fitbase-widget .checkbox_wrapper label input:checked ~ span{
				background: {{getPrimaryColor}} !important;
				border-color: {{getPrimaryColor}} !important;
			}
			#fitbase-widget .checkbox_wrapper label input[value=true] + span{
				box-shadow: 1px 5px 14px {{getPrimaryColor}}80 !important;
			}
			#fitbase-widget .modal_wrapper .registration_form .btn:not(.btn-white){
				background: {{getPrimaryColor}} !important;
			}
			#fitbase-widget .modal_wrapper .registration_form .btn[disabled]{
				background: {{getPrimaryColor}}55 !important;
			}
			#fitbase-widget .v-select li:hover, #fitbase-widget .v-select li:focus, #fitbase-widget .v-select li:active, #fitbase-widget .v-select .vs__dropdown-option--highlight{
				background: {{getPrimaryColor}} !important;
			}
			#fitbase-widget .link_text_m, #fitbase-widget .link_text_s{
				color: {{getPrimaryColor}} !important;
			}
			#fitbase-widget .days_traveller .day_item.today .day_item_number{
				background-color: {{getPrimaryColor}}55 !important;
			}
			#fitbase-widget .days_traveller .day_item.active .day_item_number{
				background-color: {{getPrimaryColor}} !important;
			}
			#fitbase-widget .days_traveller .day_item.today.active .day_item_label, #fitbase-widget .days_traveller .day_item.active .day_item_label{
				color: {{getPrimaryColor}} !important;
			}
			#fitbase-widget .show_filters_button_label{
				color: {{getPrimaryColor}} !important;
			}
			#fitbase-widget .show_filters_button .icon_wrapper svg path{
				fill: {{getPrimaryColor}} !important;
			}
			#fitbase-widget .filters_menu .circle_icon{
				background-color: {{getPrimaryColor}} !important;
			}
			#fitbase-widget .vue-checkbox-group .vue-checkbox--checked .vue-checkbox__inner:after{
				border-bottom: 2px solid {{getPrimaryColor}} !important;
				border-right: 2px solid {{getPrimaryColor}} !important;
			}
			#fitbase-widget .btn-white.selected{
				background-color: {{getPrimaryColor}} !important;
			}
			#fitbase-widget.desktop .wrapper .calendar .fc-day-today .fc-col-header_day_title .fc-col-header_date{
				color: {{getPrimaryColor}} !important;
			}
			#fitbase-widget .btn-branch.selected{
				background-color: {{getPrimaryColor}} !important;
			}
			#fitbase-widget .enter_btn a{
				color: {{getPrimaryColor}} !important;
			}
			#fitbase-widget .enter_btn a i path{
				fill: {{getPrimaryColor}} !important;
			}
			#fitbase-widget .checkbox_wrapper[value=true]{
				background-color: {{getPrimaryColor}} !important;
			}
		</v-style>
		<Mobile v-if="isTouchDevice"/>
		<Desktop v-else/>
		<div class="manufactured-by" v-if="getEmbeddedType !== 'lk'"><span>Работает на</span> <a href="https://fitbase.io"><img :src="getLogoUrl" alt="FITBASE"></a></div>
	</div>
</template>

<script>
import {storeToComputed} from "./components/mixins/storeToComputed";
import {SanFrancisco} from "./components/fonts";
import Desktop from './Desktop';
import Mobile from './Mobile';
import Vuex from 'vuex';
import store from './store';
import Vue from 'vue';
import {LIGHT_THEME, DARK_THEME} from "./components/constants";
import {desktopNormalWidth, desktopWidth, tabWidth, mediumPhoneWidth} from "./variables";
import {getFirstDayOfCurrentWeek, getLastDayOfCurrentWeek, getToday} from "./utils/dates";
import {eventBus} from "./main";
import {DIRECTION_BACKWARD} from "./components/Mobile/CalendarHeader/constants";

Vue.component('v-style', {
	render: function (createElement) {
		return createElement('style', this.$slots.default)
	}
});

Vue.use(Vuex)

export default {
	store: new Vuex.Store(store),
	name: "FitbaseWidget",
	mixins: [storeToComputed],
	components: {
		Desktop,
		Mobile,
	},
	data() {
		return {
			// была ли  хотя бы раз произведена оценка ширины контейнера, если нет -- то можно забирать опции для фильтров
			firstScreenIsDispatched: false,
			domainName: '',
		}
	},
	created() {
		this.DARK_THEME = DARK_THEME;

		eventBus.$on('setClubFilter', () => {
			if (this.isTouchDevice) {
				eventBus.$emit('resetAllMenuFilters');
				this.swipeToFirstWeek();
			} else {
				eventBus.$emit('goToday');
			}

			this.getFilters();

			//в разных клубах разный расклад по наличию ивентов на 1ой и 2ой неделе
			this.getScheduleNextWeek();

			if (!this.isTouchDevice) {
				this.setFirstWeekIsActive();
			}
		});

		eventBus.$on('clearFilters', () => {
			//(Кнопка "Сбросить" -- только десктоп)
			if (!this.isTouchDevice) {
				this.$store.dispatch('filters/clearFilters');
				this.getFilters();
				this.getScheduleNextWeek();
				this.setFirstWeekIsActive();
			}
		});
		eventBus.$on('resetAllMenuFilters', () => {
			//(Кнопка "Сбросить все" -- только моб)
			if (this.isTouchDevice) {
				this.swipeToFirstWeek();
				this.getFilters();
			}
		});

		const shadowRoot = document.getElementsByTagName('fitbase-widget')[0];
		let club = 1;
		let uri = window.location.search.substring(1);
		let params = new URLSearchParams(uri);
		let uriClub = params.get("club");
		let dataClub = shadowRoot.getAttribute('data-club');
		if (uriClub) { club = uriClub; }
		if (dataClub) { club = dataClub; }

		this.$store.dispatch('filters/setClubFilter', club);
		//подготовка домена для запросов API и типа цветовой схемы
		const domainName = shadowRoot.getAttribute('domain') !== null ? shadowRoot.getAttribute('domain').replace(/\/$/, "") : '';
		this.domainName = domainName;
		const theme = shadowRoot.getAttribute('theme') !== null ? shadowRoot.getAttribute('theme') : LIGHT_THEME;
		this.$store.dispatch('ui/setTheme', theme);
		this.$store.dispatch('ui/setDomain', domainName);

		const embeddedType = shadowRoot.getAttribute('embedded-type') !== null ? shadowRoot.getAttribute('embedded-type') : '';
		this.$store.dispatch('ui/setEmbeddedType', embeddedType);

		//получаем опции для фильтра клубов
		this.$store.dispatch('filters/getClubFilterOptions', {apiUrl: domainName});
		//есть ли на следующей неделе занятия и дата последнего занятия за текущую и следующую недели
		this.getScheduleNextWeek();
		//маска для телефона (модальная форма регистрации)
		//this.$store.dispatch('events/fetchPhoneMask', {apiUrl: domainName});
		this.$store.dispatch('events/fetchSettings', {apiUrl: domainName});
		//установка шрифтов
		SanFrancisco.forEach((font) => {
			const fontFaceStyle = document.createElement('style');
			fontFaceStyle.textContent = font;
			shadowRoot.appendChild(fontFaceStyle);
		});
		setTimeout(this.screenDispatcher,0)
		//при изменении ширины экрана - по необходимости, переключаем компонент Mobile/Desktop
		window.addEventListener("resize", this.onWindowResizeHandler);
		this.setFirstWeekIsActive();
	},
	methods: {
		swipeToFirstWeek: function() {
			if (!this.firstWeek && this.scheduleNextWeek.isHavingSchedulesNextWeek) {
				eventBus.$emit('swipeAnimate', DIRECTION_BACKWARD);
			}
		},
		//делаем первую неделю активной (исп-ся для условной отрисовки навигации по неделям десктоп-календаря)
		setFirstWeekIsActive: function() {
			this.$store.dispatch('calendar/setFirstWeek', true)
		},
		getScheduleNextWeek: function() {
			this.$store.dispatch('calendar/getScheduleNextWeek', {
				apiUrl: this.domain,
				clubId: this.filter.clubFilter
			});
		},
		getFilters: function () {
			this.$store.dispatch('filters/getFilterOptions', {
				dateStart: this.start,
				viewName: this.isTouchDevice ? 'day' : 'week',
				clubId: this.filter.clubFilter,
				apiUrl: this.domain
			});
		},
		screenDispatcher: function () {
			//анализируем ширину экрана/тип устройства
			let isMobilePhone = false;
			let isMediumMobilePhone = false;

			const container = document.getElementsByTagName('fitbase-widget')[0].parentNode;
			const containerWidth = container.getBoundingClientRect().width;

			const currentIsTouch = this.isTouchDevice;
			const isTouch = containerWidth < desktopWidth;

			if (currentIsTouch !== isTouch && this.firstScreenIsDispatched === true) {
				//забираем фильтры при смене ширины контейнера'
				this.getFilters();
			}

			if (isTouch) {
				if (containerWidth < tabWidth) {
					isMobilePhone = true;
					if (containerWidth >= mediumPhoneWidth) {
						isMediumMobilePhone = true;
					}
				}
			}

			const isSmallDesktop = !isTouch && containerWidth < desktopNormalWidth;

			this.$store.dispatch('ui/setIsTouchDevice', isTouch);
			this.$store.dispatch('ui/setIsMobilePhone', isMobilePhone);
			this.$store.dispatch('ui/setIsMediumMobilePhone', isMediumMobilePhone);
			this.$store.dispatch('ui/setIsSmallDesktop', isSmallDesktop);

			if (!this.firstScreenIsDispatched) {
				//todo в календар.вью onUpdateDates - если взято ивентов 0 раз - не делаем то что происходит ниже (потому что инитный проход)
				//todo если это смена экрана то делаем! или норм...подумать
				if (isTouch) {
					const today = getToday();
					this.$store.dispatch('calendar/setStart', today);
					this.$store.dispatch('calendar/setEnd', today);
				} else {
					this.$store.dispatch('calendar/setStart', getFirstDayOfCurrentWeek());
					this.$store.dispatch('calendar/setEnd', getLastDayOfCurrentWeek());
				}
				//////////////////////////////////////////////

				this.getFilters();
				this.firstScreenIsDispatched = true;
			}
		},
		onWindowResizeHandler: function () {
			this.screenDispatcher();
		},
		//
	},
	computed: {
		getLogoUrl: function () {
			return this.domainName + '/css/images/logo_widget.svg';
		},
		getPrimaryColor: function () {
			return this.$store.getters['events/primaryWidgetColor'];
		},
		getEmbeddedType: function () {
			return this.$store.getters['ui/embeddedType'];
		}
	},
	destroyed() {
		window.removeEventListener("resize", this.onWindowResizeHandler);
	},
}
</script>

<style lang="scss">
	@import "./assets/scss/mixins&vars";
	@import "./assets/scss/vue-select";
	@import "./assets/scss/components";
	@import "./assets/scss/fc.min";
	@import "./assets/scss/app";
</style>
