<template>
	<Checkbox
		v-model="isOnline"
		:size="this.size"
		:default="this.isOnline"
		:color="this.bg"
		filter="online"
	>
		<div class="checkbox_label">
			<IconBase
				icon-name="online"
				title="Онлайн"
				:showIconDescription="true"
				:show-icon="this.showIcon"
				:no-divider="this.noDivider"
			>
				<IconOnline />
			</IconBase>
		</div>
	</Checkbox>
</template>

<script>
import Checkbox from "../../FormControl/Checkbox";
import IconBase from "../../icons/IconBase";
import IconOnline from "../../icons/IconOnline"
import {eventBus} from "../../../main";
import {storeToComputed} from "../../mixins/storeToComputed";

export default {
	name: "OnlineFilter",
	components: {Checkbox, IconBase, IconOnline},
	mixins: [storeToComputed],
	created() {
		eventBus.$on('resetAllMenuFilters', () => {
			this.isOnline = false;
		});
		eventBus.$on('setClubFilter', () => {
			this.isOnline = false;
		});
	},
	data() {
		return {
			isOnline: this.$store.state.filters.filter.onlineFilter
		}
	},
	props: {
		size: {
			type: String,
			default: 'm'
		}
	},
	methods: {
		setOnlineFilter: function () {
			this.$store.dispatch(
				'filters/setOnlineFilter', this.isOnline
			);
		},
	},
	watch: {
		'isOnline': {
			handler: function () {
				this.setOnlineFilter();
			},
		},
	},
	computed: {
		bg() {
			return this.$store.getters['events/primaryWidgetColor'];
		}
	}
}
</script>
